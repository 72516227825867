<soft-list-page [loadingState]="loadingState()"
                [items]="all()"
                (itemClick)="onItemClick($event)">
  <soft-page-header>
    <soft-meta-query-bar [metaForm]="queryMeta"
                         [query]="query()"
                         (queryChange)="setQuery($event)"></soft-meta-query-bar>
  </soft-page-header>
  <ng-template let-item='item'>
      <soft-dynamic-object [definition]="$any(definition())" [value]="item"></soft-dynamic-object>
      <!--<ng-container *ngComponentOutlet="timeReportComponent; inputs: {value: item, meta: meta}"></ng-container>-->
  </ng-template>
  <soft-page-summary>
    @for (sum of timeReportStore.sum.state(); track sum) {
      <div class="soft-card font-semibold text-sm flex flex-row justify-end">
        Gesamt: {{sum.duration | duration }}
      </div>
    }
  </soft-page-summary>
</soft-list-page>
