import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaDirective, MetaFieldComponent, MetaObject } from '@softline/dynamic';
import { TimeReport } from '../../models/time-report.model';
import { UiCoreModule } from '@softline/ui-core';

@Component({
    selector: 'soft-time-report-item',
    imports: [CommonModule, UiCoreModule, MetaDirective, MetaFieldComponent],
    templateUrl: './time-report-item.component.html',
    styleUrl: './time-report-item.component.scss'
})
export class TimeReportItemComponent
{
  meta = input.required<MetaObject<TimeReport>>();
  value = input.required<TimeReport>();
}
