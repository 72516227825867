import {
  ImportedNgModuleProviders,
  importProvidersFrom,
  inject,
  StaticProvider,
  provideEnvironmentInitializer,
} from '@angular/core';
import { PwaUpdateService } from './update/services/pwa-update.service';
import { Store, StoreModule } from '@softline/core';
import {
  SOFTLINE_CONFIG_PWA_CHECK_UPDATE_INTERVALL,
  SOFTLINE_FEATURE_PWA_INSTALL,
  SOFTLINE_FEATURE_PWA_UPDATE,
  SOFTLINE_SETTINGS_PWA_INSTALL,
  SOFTLINE_SETTINGS_PWA_UPDATE,
} from './pwa.shared';
import { PwaUpdateStore } from './update/store';
import {
  CommandStore,
  SOFTLINE_CONFIG_SETTINGS,
  SOFTLINE_FEATURE_COMMANDS,
} from '@softline/application';
import { PwaUpdateSettingsComponent } from './update/settings/pwa-update-settings.component';
import { PwaInstallSettingsComponent } from './install/settings/pwa-install-settings/pwa-install-settings.component';
import { PwaInstallService } from './install/services/pwa-install.service';
import { PwaInstallStore } from './install/store';
import { map, of } from 'rxjs';
import { Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';

export const pwaProviders: (StaticProvider | ImportedNgModuleProviders)[] = [
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_PWA_UPDATE,
      feature: PwaUpdateStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_PWA_INSTALL,
      feature: PwaInstallStore.feature,
    })
  ),
  { provide: PwaUpdateService, useClass: PwaUpdateService },
  { provide: PwaInstallService, useClass: PwaInstallService },
  { provide: SOFTLINE_CONFIG_PWA_CHECK_UPDATE_INTERVALL, useValue: 30 * 60 * 1000 },
  provideEnvironmentInitializer(() => {
    const updateService = inject(PwaUpdateService);
    const installService = inject(PwaInstallService);
    const store = inject(Store);
    const router = inject(Router);
    const plattform = inject(Platform);
    updateService.init();
    installService.init();
    store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
      name: 'pwaProviders',
      commands: [
        {
          name: '#PWA.INSTALL.SETTINGS.TITLE',
          icon: 'fa-regular fa-display-arrow-down',
          execute: async () => {
            await router.navigate(['settings'], { fragment: 'pwaInstall' });
          },
          class: 'menu main-menu main-menu-bottom',
          isVisible: store
            .observe(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.getters.data)
            .pipe(map((o) => (!o?.isPwa && (plattform.IOS || plattform.BLINK)) ?? true)),
        },
      ],
      priority: 1,
    });
  }),
  {
    provide: SOFTLINE_CONFIG_SETTINGS,
    multi: true,
    useFactory: (store: Store) => {
      return {
        key: SOFTLINE_SETTINGS_PWA_UPDATE,
        component: PwaUpdateSettingsComponent,
        title: '#PWA.UPDATE.SETTINGS.TITLE',
        icon: 'fa-regular fa-arrows-rotate',
        visible: store
          .observe(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.getters.data)
          .pipe(map((o) => o?.hasServiceWorker ?? false)),
      };
    },
    deps: [Store],
  },
  {
    provide: SOFTLINE_CONFIG_SETTINGS,
    multi: true,
    useFactory: (store: Store) => {
      return {
        key: SOFTLINE_SETTINGS_PWA_INSTALL,
        component: PwaInstallSettingsComponent,
        title: '#PWA.INSTALL.SETTINGS.TITLE',
        icon: 'fa-regular fa-display-arrow-down',
        visible: store
          .observe(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.getters.data)
          .pipe(map((o) => !o?.isPwa)),
      };
    },
    deps: [Store],
  },
];
