import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@softline/core';
import {Objekt} from '../../data/objekt';
import {Modal, UiCoreModule} from '@softline/ui-core';
import {RecentObjectsStore, SOFTLINE_FEATURE_SHOP_RECENT_OBJECTS} from '../../shop.shared';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'lib-recent-objects-dialog',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './recent-objects-dialog.component.html',
    styleUrls: ['./recent-objects-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecentObjectsDialogComponent implements OnInit, Modal<Objekt | null> {

  close!: (result: Objekt | null) => void;

  recentObjects$: Observable<readonly Objekt[]> = this.store.observe(SOFTLINE_FEATURE_SHOP_RECENT_OBJECTS, RecentObjectsStore.getters.all);
  loading$: Observable<boolean> = this.store.observe(SOFTLINE_FEATURE_SHOP_RECENT_OBJECTS, RecentObjectsStore.getters.loading);

  constructor(private store: Store) { }

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_SHOP_RECENT_OBJECTS, RecentObjectsStore.actions.loadMany, { clear: true, queryParams: { letzenArbeitsberichte: 8 } }
    );
  }

  registerCloseHandler(handler: (result: (Objekt | null)) => void): void {
    this.close = handler;
  }
}
