<form class="mt-2" [formGroup]="form" (ngSubmit)="onSubmit()" #formRef="ngForm">
  <div class="gap-2 grid">
    <div class="flex flex-col">
      <label class="soft-label top">Anrede</label>
      <soft-input formControlName="anrede"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Name 1</label>
      <soft-input formControlName="name_1"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Name 2</label>
      <soft-input formControlName="name_2"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Name 3</label>
      <soft-input formControlName="name_3"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Strasse</label>
      <soft-input formControlName="strasse"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">PLZ</label>
      <soft-query-field-ok
        name="orte"
        formControlName="ort"
        title="PLZ"
      ></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Ort</label>
      <soft-query-field-ok
        name="orte"
        formControlName="ort"
        title="Ort"
      ></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Lieferant</label>
      <soft-query-field-ok
        #ktostammLieferant
        name="ktostamm.lieferant"
        formControlName="lieferantennummer"
        title="Lieferantennummer"
      ></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Kunde</label>
      <soft-query-field-ok
        name="ktostamm.kunde"
        formControlName="kundennummer"
        title="Kundennummer"
      ></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Kundebezeichnung</label>
      <soft-input formControlName="kurzbezeichnung"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Kundengruppe</label>
      <soft-query-field-ok
        name="kundengruppe"
        formControlName="kundengruppe"
        title="Kundengruppe"
      ></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Zahlungsart</label>
      <soft-query-field-ok
        name="zahlungsart"
        formControlName="zahlungskennzeichen"
        title="Zahlungsart"
      ></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Email</label>
      <soft-input formControlName="mail"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">GLN</label>
      <soft-input formControlName="gln"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Branche</label>
      <soft-query-field-ok
        name="zahlungsart"
        formControlName="branche"
        title="Branche"
      ></soft-query-field-ok>
    </div>

    <div class="flex flex-col">
      <label class="soft-label top">Rechnungslieferanten</label>
      <soft-radio-group
        class="flex flex-row w-full"
        formControlName="rechnungslieferanten"
      >
        <soft-radio-option class="w-full mr-4" [value]="0">
          <span class="soft-label right">Ja</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="1">
          <span class="soft-label right">Nein</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="2">
          <span class="soft-label right">Alle</span>
        </soft-radio-option>
      </soft-radio-group>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Lieferadresse</label>
      <soft-radio-group class="flex flex-row w-full" formControlName="lieferadresse">
        <soft-radio-option class="w-full mr-4" [value]="0">
          <span class="soft-label right">Lieferant</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="1">
          <span class="soft-label right">Lieferadresse</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="2">
          <span class="soft-label right">Alle</span>
        </soft-radio-option>
      </soft-radio-group>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Gelöscht</label>
      <soft-radio-group class="flex flex-row w-full" formControlName="geloescht">
        <soft-radio-option class="w-full mr-4" [value]="0">
          <span class="soft-label right">Nicht gelöscht</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="1">
          <span class="soft-label right">Gelöscht</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="2">
          <span class="soft-label right">Alle</span>
        </soft-radio-option>
      </soft-radio-group>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Aktiv</label>
      <soft-radio-group class="flex flex-row w-full" formControlName="aktiv">
        <soft-radio-option class="w-full mr-4" [value]="0">
          <span class="soft-label right">Ja</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="1">
          <span class="soft-label right">Nein</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="2">
          <span class="soft-label right">Alle</span>
        </soft-radio-option>
      </soft-radio-group>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Gesperrt</label>
      <soft-radio-group class="flex flex-row w-full" formControlName="sperre">
        <soft-radio-option class="w-full mr-4" [value]="0">
          <span class="soft-label right">Nicht gesperrt</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="1">
          <span class="soft-label right">Gesperrt</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="2">
          <span class="soft-label right">Alle</span>
        </soft-radio-option>
      </soft-radio-group>
    </div>
  </div>
</form>
