import { Component, OnInit } from '@angular/core';
import { FieldOkDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { NgIf } from '@angular/common';
import {
  FieldValidationDirective,
  UiCorePipesModule,
  ValueConverterDirective,
} from '@softline/ui-core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';
import {
  BooleanRulePipe,
  FormatRulePipe,
  MultiselectFieldOkComponent,
  ResolveParametersPipe,
  ResultMappingConverterPipe,
} from '@softline/dynamic';

@Component({
  selector: 'soft-dynamic-field-ok-input',
  templateUrl: './field-ok-input.component.html',
  styleUrls: ['./field-ok-input.component.scss'],
  imports: [
    NgIf,
    ValueConverterDirective,
    ReactiveFormsModule,
    FieldValidationDirective,
    FormControlPipe,
    BooleanRulePipe,
    ResultMappingConverterPipe,
    ResolveParametersPipe,
    FormatRulePipe,
    UiCorePipesModule,
    MultiselectFieldOkComponent,
  ],
})
export class MultiFieldOkInputComponent
  extends DynamicInputAtom<FieldOkDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
