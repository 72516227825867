import {Component, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import { DynamicModule, EntityView, FieldOkComponent, FieldOkResultConverter } from "@softline/dynamic";
import {UiCoreModule} from '@softline/ui-core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LocalStorageService, StorageResourceLocation, Store} from '@softline/core';
import {BehaviorSubject, lastValueFrom} from 'rxjs';
import {Vkforg} from '../../data/vkforg';
import {
  PatchContextStore,
  SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
  SOFTLINE_FEATURE_UTILITIES_VKFORGS
} from '@softapps/allgemein/utils';
import {VkforgStore} from '@softapps/allgemein/utils';
import {SOFTLINE_FEATURE_SHOP_CART} from '../../shop.shared';
import {CartStore} from '../../store/cart.store';

@Component({
    selector: 'soft-bestellung-context-editor',
    imports: [CommonModule, DynamicModule, UiCoreModule, FormsModule, ReactiveFormsModule],
    templateUrl: './bestellung-context-editor.component.html',
    styleUrls: ['./bestellung-context-editor.component.scss']
})
export class BestellungContextEditorComponent implements OnInit {

  location: StorageResourceLocation = { key: 'shopOrderLocation' }
  vkforgConverter = new FieldOkResultConverter({ 'vkforg': 'vkforg', 'kurzbezeichnung': 'name', 'id': 'id' });

  @ViewChild('vkfOrgFok', {static: true}) fieldOk?: FieldOkComponent<any>;

  vkfOrgs$ = this.store.observe(SOFTLINE_FEATURE_UTILITIES_VKFORGS, VkforgStore.getters.all)

  readonly vkforg$ = new BehaviorSubject<Vkforg | null>(null);

  readonly form = new FormGroup({
    accountid: new FormControl(undefined),
    vkforgidinternerauftrag: new FormControl(undefined)
  });

  constructor(
    private store: Store,
    private localstoreService: LocalStorageService,
  ) { }

  async ngOnInit(): Promise<void> {
    const shopOrderLocation = await lastValueFrom(this.localstoreService.get(this.location));

    if (shopOrderLocation && typeof shopOrderLocation === 'string') {
      await this.selectVkforg(JSON.parse(shopOrderLocation))
    }
  }

  async selectVkforg(vkforg: any | null): Promise<void> {
    console.log('vkforg: ', vkforg)

    this.vkforg$.next(vkforg);

    await lastValueFrom(this.localstoreService.delete(this.location));
    await lastValueFrom(this.localstoreService.create(this.location, JSON.stringify(vkforg)));
    this.form.patchValue({ vkforgidinternerauftrag: vkforg });

    this.store.commit(
      SOFTLINE_FEATURE_SHOP_CART,
      CartStore.mutations.setOrderLocation,
      { location: vkforg }
    );

    console.log('ORDER LOCATION: ', this.store.get(SOFTLINE_FEATURE_SHOP_CART, CartStore.getters.orderLocation))

    this.store.commit(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.mutations.patch, { vkforgidinternerauftrag: vkforg?.id }
    )
  }
}
