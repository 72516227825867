import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store} from '@softline/core';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {Voucher} from '../../../data/voucher';
import {SOFTLINE_FEATURE_SHOP_ORDER_HISTORY} from '../../../shop.shared';
import {OrderHistoryStore} from '../../../store/order-history.store';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {ToShopItemsPipe} from '../../../pipes/to-shop-items.pipe';
import {ItemListComponent} from '../../../components/item-list/item-list.component';

@Component({
    selector: 'lib-order-detail',
    imports: [CommonModule, UiCoreModule, ToShopItemsPipe, ItemListComponent],
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailComponent implements OnInit {

  order$: Observable<Voucher | undefined> = combineLatest([
    this.activatedRoute.paramMap.pipe(map(o => o.get('id'))),
    this.store.observe(SOFTLINE_FEATURE_SHOP_ORDER_HISTORY, OrderHistoryStore.getters.entities)
  ]).pipe(map(([id, entities]) => {
    if (id !== null) {
      return entities[id];
    }
    return undefined;
  }));

  constructor(private store: Store, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {}

  async navigateBack(): Promise<void> {
    await this.router.navigate(['../../orders'], { relativeTo: this.activatedRoute });
  }
}
