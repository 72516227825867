import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {RequestError, Store} from '@softline/core';
import {combineLatestWith, Observable} from 'rxjs';
import {ShopItemAttribute} from '../../data/item-attribute';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {
  BackNavigable,
  BackNavigationService,
  showRequestErrors,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import {AttributeStore, SOFTLINE_FEATURE_SHOP_ATTRIBUTE, SOFTLINE_FEATURE_SHOP_FAVORITES} from '../../shop.shared';
import {FavoritesStore} from '../../store/favorites.store';
import {MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule} from '@softline/ui-core';
import {AttributeNavigationService} from '../../services/attribute-navigation.service';
import {CommonModule} from '@angular/common';
import {
  ItemNavigationContainerComponent
} from '../../components/item-navigation-container/item-navigation-container.component';
import {ListItemComponent} from '../../components/list-item/list-item.component';

@Component({
    selector: 'lib-attribute-list',
    imports: [CommonModule, UiCoreModule, ListItemComponent, ItemNavigationContainerComponent],
    templateUrl: './attribute-list.component.html',
    styleUrls: ['./attribute-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributeListComponent implements OnInit, OnDestroy, BackNavigable {

  private readonly selectedAttribute$ = this.attributeNavigationService.activeAttribute$;

  readonly attributes$: Observable<readonly ShopItemAttribute[]> = this.store.observe(
    SOFTLINE_FEATURE_SHOP_ATTRIBUTE,
    AttributeStore.getters.all
  );

  readonly visibleAttributes$ = this.attributes$.pipe(
    combineLatestWith(this.selectedAttribute$),
    map(([attributes, selected]) => {
      console.log(attributes);
      console.log(selected);
      if (!selected)
        return attributes.filter(o => !o.parentId);
      else
        return attributes.filter(o => o.parentId === selected.id);
    })
  );

  readonly loading$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_SHOP_ATTRIBUTE,
    AttributeStore.getters.loading
  );

  readonly trackByFn = (_: number, item: ShopItemAttribute) => item.id;

  constructor(
    private store: Store,
    protected router: Router,
    private attributeNavigationService: AttributeNavigationService,
    private backNavigationService: BackNavigationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#SHOP.ITEM_GROUPS.TITLE');
    this.backNavigationService.set(this);

    try {
      await this.store.dispatch(SOFTLINE_FEATURE_SHOP_FAVORITES, FavoritesStore.actions.loadMany, { clear: true });
    } catch (e: any) {
      if (e instanceof RequestError) {
        await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.error, {
          title: 'Fehler',
          message: 'Es ist ein Fehler beim Laden der Favoriten aufgetreten!'
        });
      } else {
        showRequestErrors(this.store, e);
      }
    }

    await this.store.dispatch(
      SOFTLINE_FEATURE_SHOP_ATTRIBUTE,
      AttributeStore.actions.loadMany,
      { clear: true }
    );
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  async onSelect(attribute: ShopItemAttribute | null): Promise<void> {
    await this.attributeNavigationService.selectAttribute(attribute);
  }

  async navigateToFavorites(): Promise<void> {
    await this.router.navigate(['/shop/favorites']);
  }
}
