import { Component, OnDestroy, OnInit } from '@angular/core';
import { SOFTLINE_FEATURE_TITLE, TitleStore } from '@softline/application';
import { Store } from '@softline/core';
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'app-documentation',
    templateUrl: './documentation.component.html',
    styleUrls: ['./documentation.component.scss'],
    imports: [RouterModule]
})
export class DocumentationComponent implements OnInit, OnDestroy {
  constructor(private store: Store, protected router: Router) {}

  ngOnInit(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#DOCUMENTATION.TITLE'
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
  }
}
