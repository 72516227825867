<div *ngIf="form && definition" class="soft-container soft-container-column relative">
  <label class="soft-label soft-label-top">
    {{ definition.title }}
    <span class="required-indicator" *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <div class="relative w-full">
    <soft-value-converter
      [formControl]="form | formControl: definition.name"
      [converter]="definition.resultMapping | resultMappingConverter"
      softFieldValidation
    >
      <soft-multiselect-field-ok
        [name]="definition.fieldOk"
        [parameters]="definition.parameters || {} | resolveParameters: form.value"
        [readonly]="definition.readonly | booleanRule: form.value"
        [placeholder]="definition.placeholder"
        [template]="definition.format ? template : undefined"
        [title]="definition.title"
        #convertValue
      >
      </soft-multiselect-field-ok>
    </soft-value-converter>
  </div>
</div>

<ng-template let-entity="entity" #template>
  <span>
    {{ definition.format | formatRule: form.value | interpolate: entity }}
  </span>
</ng-template>
