import {
  Component,
  computed,
  HostBinding,
  inject,
  INJECTOR,
  Injector,
  input,
  isSignal,
  runInInjectionContext,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from '../../../../../../framework/application/src/lib/menu/menu-item';
import { Command2Service } from '../../../../../../framework/application/src/lib/commands/command2.service';
import { UiCoreModule } from '@softline/ui-core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { of } from 'rxjs';

@Component({
  selector: 'soft-menu-item',
  imports: [CommonModule, UiCoreModule, RouterModule],
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss',
})
export class MenuItemComponent {
  router = inject(Router);
  commandService = inject(Command2Service);
  injector = inject(INJECTOR);

  menuItem = input.required<MenuItem>();

  running = signal(false);
  route = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((e) => {
        return (e as NavigationEnd).url;
      })
    )
  );

  canExecute = computed(() => {
    const menuItem = this.menuItem();
    switch (menuItem.type) {
      case 'route':
        return signal(true);
      case 'link':
        return signal(true);
      case 'command':
        return this.commandService.canExecute(menuItem.name);
      case 'execute':
        return isSignal(menuItem.canExecute)
          ? computed(() =>
              isSignal(menuItem.canExecute) ? menuItem.canExecute() : menuItem.canExecute
            )
          : computed(() => menuItem.canExecute);
    }
  });

  active = computed(() => {
    const menuItem = this.menuItem();
    const route = this.route(); // Damit auch wenn sich keine MenuItems ändern die als aktiv angezeigte Route aktualisiert wird.
    switch (menuItem.type) {
      case 'route':
        const urlTree = this.router.createUrlTree(
          menuItem.routerLink,
          menuItem?.routerLinkParams
        );
        return this.router.isActive(urlTree, {
          paths: 'exact',
          queryParams: 'ignored',
          matrixParams: 'ignored',
          fragment: menuItem?.routerLinkParams ? 'exact' : 'ignored',
        });
      case 'command':
        return false;
      default:
        return false;
    }
  });

  @HostBinding('class')
  get class() {
    return this.active() ? 'active' : '';
  }

  async execute(menuItem: MenuItem): Promise<void> {
    this.running.set(true);
    try {
      switch (menuItem.type) {
        case 'route':
          if (menuItem.target === '_blank') {
            const urlTree = this.router.createUrlTree(menuItem.routerLink);
            const url = this.router.serializeUrl(urlTree);
            window.open(url, '_blank');
          } else
            await this.router.navigate(menuItem.routerLink, menuItem.routerLinkParams);
          break;
        case 'link':
          window.open(menuItem.src, menuItem.target || '_self');
          break;
        case 'command':
          await this.commandService.execute(menuItem.name);
          break;
        case 'execute':
          if (isSignal(menuItem.canExecute) ? menuItem.canExecute() : menuItem.canExecute)
            await menuItem.execute();
          break;
      }
    } catch (e) {
      throw e;
    } finally {
      this.running.set(false);
    }
  }

  protected readonly isSignal = isSignal;
}
