import { Component, EventEmitter, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResolvableModal } from '@softline/ui-core';
import { ArtstammQueryView } from '../query-view/artstamm.query-view';

@Component({
  selector: 'soft-artstamm-search-result-query-modal',
  imports: [CommonModule, ArtstammQueryView],
  templateUrl: './artstamm-search-result-query-modal.component.html',
  styleUrl: './artstamm-search-result-query-modal.component.scss',
})
export class ArtstammSearchResultQueryModalComponent extends ResolvableModal<any> {
  readonly query = signal<object | null>(null);

  readonly requestSubmit = new EventEmitter<any>();

  queryChange = (query: object) => {
    this.query.set(query);
  };

  submitFn = (query: object, value?: object) => {
    const nonNullQueryObject = {};

    for (const [key, value] of Object.entries(query)) {
      if (value === null || value === undefined) continue;

      nonNullQueryObject[key] = value;
    }

    this.resolve({ query: nonNullQueryObject, fullValue: value });
  };
}
