import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  viewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldValidationDirective, UiCoreModule } from '@softline/ui-core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  FieldOkComponent,
  FieldOkQueryComponent,
  QueryFieldOkComponent,
} from '@softline/dynamic';
import { Subscription } from 'rxjs';

@Component({
  selector: 'soft-artstamm-field-ok-data-view',
  imports: [
    CommonModule,
    UiCoreModule,
    ReactiveFormsModule,
    FieldOkComponent,
    QueryFieldOkComponent,
  ],
  templateUrl: './artstamm.query-view.html',
  styleUrls: ['./artstamm.query-view.scss'],
})
export class ArtstammQueryView
  implements FieldOkQueryComponent<object>, OnInit, OnDestroy
{
  private subscription?: Subscription;
  private requestSubmitSubscription?: Subscription;

  form = new FormGroup({
    suche: new FormControl<string | null>(null),
    artikelnummer: new FormControl<string | null>(null),
    fremdartnr: new FormControl<string | null>(null),
    lieferantennummer: new FormControl<string | null>(null),
    arthauptgrp: new FormControl<string | null>(null),
    artuntergrp: new FormControl<string | null>(null),
    ean: new FormControl<string | null>(null),
    kondgruppe: new FormControl<string | null>(null),
    lieferantenartikelnummer: new FormControl<string | null>(null),
  });

  @Input() requestSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Input() queryChange: (query: object) => void = () => {};
  @Input() submit: (query: object, fullResult?: object) => void = () => {};
  @ViewChildren(FieldValidationDirective, { read: FieldValidationDirective })
  validations!: QueryList<FieldValidationDirective>;

  formRef = viewChild<FormGroupDirective>('formRef');
  query: any;

  lieferantValue: object | null = null;

  async ngOnInit(): Promise<void> {
    this.subscription = this.form.valueChanges.subscribe((o: object) => {
      if (o && o['lieferantennummer']) {
        o['lieferantennummer'] = o['lieferantennummer']['nummer'];
      }

      this.queryChange(o);
    });
    if (this.requestSubmit)
      this.requestSubmitSubscription = this.requestSubmit.subscribe((o) =>
        this.formRef()?.onSubmit(new Event('submit'))
      );
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) this.subscription.unsubscribe();
    this.subscription = undefined;
    if (this.requestSubmitSubscription && !this.requestSubmitSubscription.closed)
      this.requestSubmitSubscription.unsubscribe();
    this.requestSubmitSubscription = undefined;
  }

  fullFormValue(): object {
    return {
      ...this.form.getRawValue(),
      lieferantennummer: this.lieferantValue,
    };
  }

  lieferantValueChange(value: any) {
    this.lieferantValue = value;
  }

  async onSubmit(): Promise<void> {
    this.form.updateValueAndValidity();
    if (this.form.errors) return;

    this.submit(this.form.value);
  }
}
