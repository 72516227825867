<soft-page>
  <soft-page-tab name="search" [icon]="'fa-regular fa-search'" [title]="'Suche'">
    <ng-template>
      <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
    </ng-template>
  </soft-page-tab>
  <soft-page-tab name="scan" [icon]="'fa-regular fa-barcode-scan'" [title]="'Scan'">
    <ng-template>
      <ng-container *ngTemplateOutlet="scanTemplate"></ng-container>
    </ng-template>
  </soft-page-tab>
  <soft-page-tab name="favorites" [icon]="'fa-regular fa-heart'" [title]="'Favoriten'">
    <ng-template>
      <ng-container *ngTemplateOutlet="favoriteTemplate"></ng-container>
    </ng-template>
  </soft-page-tab>
  <soft-page-tab name="last-used" [icon]="'fa-regular fa-history'" [title]="'Letzte'">
    <ng-template>
      <ng-container *ngTemplateOutlet="lastUsedTemplate"></ng-container>
    </ng-template>
  </soft-page-tab>
</soft-page>

<ng-template #searchTemplate>
  <div class="flex flex-col mb-1.5 mt-3">
    <soft-input
      placeholder="Suchbegriff eingeben..."
      class="mb-3"
      [value]="searchInput$ | async"
      (valueChange)="searchInput$.next($event || null)"
    >
      @if (!!strategy?.queryModalViewType) {
        <button
          (click)="openQueryModalView()"
          [ngClass]="strategy.isManualSearchActive() ? 'text-warning-600' : ''"
        >
          <soft-icon [name]="'fa-regular fa-database'"></soft-icon>
        </button>
      }
    </soft-input>

    <!-- Das ist ein Pfusch für dfh, sollte eigentlich eine spezifische Komponente in der strategy rendern statt hier fix -->
    @if (strategy?.activeFilter(); as filter) {
      <soft-chip-group class="block mb-1">
        @if (filter['query'] && !!filter['query']['suche']) {
          <soft-chip [canDelete]="true" (click)="removeFilter(filter, 'suche')">
            <span class="text-sm font-semibold">Suche:</span>
            <span class="text-sm font-normal">{{ filter['query']['suche'] }}</span>
          </soft-chip>
        }

        @if (filter['query'] && !!filter['query']['artikelnummer']) {
          <soft-chip [canDelete]="true" (click)="removeFilter(filter, 'artikelnummer')">
            <span class="text-sm font-semibold">Artikelnummer:</span>
            <span class="text-sm font-normal">{{
              filter['query']['artikelnummer']
            }}</span>
          </soft-chip>
        }

        @if (filter['query'] && !!filter['query']['lieferantennummer']) {
          <soft-chip
            [canDelete]="true"
            (click)="removeFilter(filter, 'lieferantennummer')"
          >
            <span class="text-sm font-semibold">Lieferant:</span>
            <span class="text-sm font-normal"
              >{{ filter['fullValue']['lieferantennummer']['nummer'] }} -
              {{ filter['fullValue']['lieferantennummer']['kurzbez'] }}</span
            >
          </soft-chip>
        }
      </soft-chip-group>
    }

    @if (strategy.loadingState() === 'loaded') {
      <div class="text-light text-sm">
        {{ strategy.results()?.length ?? 0 }} Ergebnisse
      </div>
    }
  </div>
  <ul class="full-width flex flex-col flex-grow">
    @if (strategy.loadingState() === 'loading') {
      <li
        class="flex flex-col px-4 py-2 gap-2 bg-surface border-b w-full"
        *softRepeat="9"
      >
        <div class="skeleton w-1/3 h-4"></div>
        <div class="skeleton w-1/2 h-5"></div>
        <div class="skeleton w-1/2 h-3"></div>
      </li>
      <li
        class="flex px-4 py-2 items-center justify-between gap-2 bg-surface border-t w-full border-b"
      ></li>
    } @else if (strategy.loadingState() === 'loaded') {
      @for (
        result of strategy.results();
        track $index;
        let first = $first;
        let last = $last
      ) {
        <li
          class="flex px-4 py-2 items-center hover:bg-hover justify-between gap-2 bg-surface w-full"
          [class.border-t]="!first"
          [class.border-b]="last"
        >
          <a (click)="onSelect(result)" class="w-full cursor-pointer">
            <ng-container
              *ngComponentOutlet="resultComponent; inputs: { result: result }"
            ></ng-container>
          </a>
          <div
            class="flex items-center justify-center w-8 h-8 p-1 cursor-pointer hover:bg-white hover:text-primary rounded"
            (click)="
              onFavoriteChange(result); $event.stopPropagation(); $event.preventDefault()
            "
          >
            <soft-icon
              [name]="
                (result | in: strategy.favorites())
                  ? 'fa-solid fa-heart'
                  : 'fa-regular fa-heart'
              "
              class="hover:text-primary"
            >
            </soft-icon>
          </div>
        </li>
      } @empty {
        <div
          class="flex flex-col flex-grow w-full items-center justify-center px-4 text-center"
        >
          <soft-icon name="fa-regular fa-face-pensive text-4xl mb-2"></soft-icon>
          <h6 class="font-semibold text-lg text-center px-4">Keine Treffer!</h6>
          <span class="text-sm text-light text-center px-4"
            >Wir konnten zum Suchbegriff "{{ debouncedSearchInput() }}" keine Treffer
            finden.</span
          >
        </div>
      }
    } @else if (strategy.loadingState() === 'error') {
      <div
        class="flex flex-col flex-grow w-full items-center justify-center px-4 text-center"
      >
        <soft-icon name="fa-regular fa-cloud-exclamation text-4xl mb-2"></soft-icon>
        <h6 class="font-semibold text-lg text-center px-4">
          Fehler beim Laden der Ergebnisse!
        </h6>
        <span class="text-sm text-light text-center px-4"
          >Bitte versuchen Sie es später noch einmal.</span
        >
      </div>
    } @else {
      <!-- IDLE State -->
      <div
        class="flex flex-col flex-grow w-full items-center justify-center px-4 text-center"
      >
        <soft-icon name="fa-regular fa-magnifying-glass text-4xl mb-2"></soft-icon>
        <h6 class="font-semibold text-lg text-center px-4">Bitte Suchbegriff eingeben</h6>
        <span class="text-sm text-light text-center px-4"
          >Die Suche wird automatisch ausgeführt sobald mehr als zwei Buchstaben
          eingegeben werden</span
        >
      </div>
    }
  </ul>
</ng-template>

<ng-template #scanTemplate>
  <soft-scan-button
    (scan)="scan.emit($event)"
    class="mt-3"
    [labelType]="[
      'ean13',
      'ean128',
      'ean8',
      'qrcode',
      'code39',
      'code93',
      'code128',
      'upc_a',
      'upc_e',
      'itf',
    ]"
  >
  </soft-scan-button>
</ng-template>

<ng-template #favoriteTemplate>
  <ul class="full-width flex flex-col flex-grow mt-3">
    @for (
      favorite of strategy.favorites();
      track $index;
      let first = $first;
      let last = $last
    ) {
      <li
        class="flex px-4 py-2 items-center hover:bg-hover justify-between gap-2 bg-surface w-full"
        [class.border-t]="!first"
        [class.border-b]="last"
      >
        <a (click)="onSelect(favorite.object)" class="w-full cursor-pointer">
          <ng-container
            *ngComponentOutlet="resultComponent; inputs: { result: favorite.object }"
          ></ng-container>
        </a>
        <div
          class="flex items-center justify-center w-8 h-8 p-1 cursor-pointer hover:bg-white hover:text-primary rounded"
          (click)="
            onFavoriteChange(favorite.object);
            $event.stopPropagation();
            $event.preventDefault()
          "
        >
          <soft-icon [name]="'fa-solid fa-heart'" class="hover:text-primary"> </soft-icon>
        </div>
      </li>
    } @empty {
      <div
        class="flex flex-col flex-grow w-full items-center justify-center px-4 text-center"
      >
        <soft-icon name="fa-regular fa-face-pensive text-4xl mb-2"></soft-icon>
        <h6 class="font-semibold text-lg text-center px-4">Keine Favoriten!</h6>
        <span class="text-sm text-light text-center px-4"
          >Sie haben der Liste noch keine Favoriten hinzugefügt.</span
        >
      </div>
    }
  </ul>
</ng-template>

<ng-template #lastUsedTemplate>
  <ul class="full-width flex flex-col flex-grow border-t">
    @for (
      lastUsed of strategy.lastUsed();
      track $index;
      let first = $first;
      let last = $last
    ) {
      <li
        class="flex px-4 py-2 items-center hover:bg-hover justify-between gap-2 bg-surface w-full"
        [class.border-t]="!first"
        [class.border-b]="last"
      >
        <a (click)="onSelect(lastUsed.object)" class="w-full cursor-pointer">
          <ng-container
            *ngComponentOutlet="resultComponent; inputs: { result: lastUsed.object }"
          ></ng-container>
        </a>
        <div
          class="flex items-center justify-center w-8 h-8 p-1 cursor-pointer hover:bg-white hover:text-primary rounded"
          (click)="
            onFavoriteChange(lastUsed.object);
            $event.stopPropagation();
            $event.preventDefault()
          "
        >
          <soft-icon
            [name]="
              (lastUsed.object | in: strategy.favorites())
                ? 'fa-solid fa-heart'
                : 'fa-regular fa-heart'
            "
            class="hover:text-primary"
          >
          </soft-icon>
        </div>
      </li>
    } @empty {
      <div
        class="flex flex-col flex-grow w-full items-center justify-center px-4 text-center"
      >
        <soft-icon name="fa-regular fa-face-pensive text-4xl mb-2"></soft-icon>
        <h6 class="font-semibold text-lg text-center px-4">Keine Einträge!</h6>
        <span class="text-sm text-light text-center px-4"
          >Sie haben aus der Liste noch keine Einträge ausgewählt.</span
        >
      </div>
    }
  </ul>
</ng-template>
