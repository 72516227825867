import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Store} from '@softline/core';
import {ShopItemAttribute} from '../../data/item-attribute';
import {Router} from '@angular/router';
import {AttributeNavigationService} from '../../services/attribute-navigation.service';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

@Component({
    selector: 'lib-item-navigation-container',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './item-navigation-container.component.html',
    styleUrls: ['./item-navigation-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemNavigationContainerComponent {

  readonly breadcrumbs$ = this.attributeNavigationService.activeAttributes$;

  constructor(
    private store: Store,
    private router: Router,
    private attributeNavigationService: AttributeNavigationService
  ) { }

  async onSelect(attribute: ShopItemAttribute | null): Promise<void> {
    await this.attributeNavigationService.selectAttribute(attribute);
    await this.router.navigate(['/shop']);
  }
}
