<soft-remote-data-container [data]="all()"
                            [loadingState]="loadingState()"
                            (cancel)="cancelLoad()">
  <soft-dynamic-list
    [definition]="$any(definition())"
    [items]="all()"
    (itemClick)="selectedChange.emit($event)"
  >
  </soft-dynamic-list>
  <hr class="mt-3 mb-2" />
  <div *ngIf="totalTime() as totalTime">
    <div class=" flex flex-row justify-between items-center flex-nowrap" [ngClass]="first ? 'mt-3' : 'mt-2'" *ngFor="let entry of totalTime; let first = first;">
      <strong class="ml-1">{{ (entry?.bewegungsart?.bezeichnung ?? '#TIME_REPORT.COMPONENTS.LIST.TOTAL') | translate }}</strong>
      <span>{{ entry?.duration | duration: 'hh:mm' }}</span>
    </div>
  </div>
</soft-remote-data-container>

