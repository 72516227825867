import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  viewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldValidationDirective, UiCoreModule } from '@softline/ui-core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  FieldOkComponent,
  FieldOkQueryComponent,
  QueryFieldOkComponent,
} from '@softline/dynamic';
import { Subscription } from 'rxjs';

@Component({
  selector: 'soft-field-ok-data-view',
  imports: [
    CommonModule,
    UiCoreModule,
    ReactiveFormsModule,
    FieldOkComponent,
    QueryFieldOkComponent,
  ],
  templateUrl: './ktostamm-lieferant.query-view.html',
  styleUrls: ['./ktostamm-lieferant.query-view.scss'],
})
export class KtostammLieferantQueryView
  implements FieldOkQueryComponent<object>, OnInit, OnDestroy
{
  private subscription?: Subscription;
  private requestSubmitSubscription?: Subscription;

  ktostammLieferant = viewChild<QueryFieldOkComponent>('ktostammLieferant');

  form = new FormGroup({
    anrede: new FormControl<string | null>(null),
    name_1: new FormControl<string | null>(null),
    name_2: new FormControl<string | null>(null),
    name_3: new FormControl<string | null>(null),
    strasse: new FormControl<string | null>(null),
    plz: new FormControl<string | null>(null),
    ort: new FormControl<string | null>(null),
    lieferantennummer: new FormControl<string | null>(null),
    kundennummer: new FormControl<string | null>(null),
    kurzbezeichnung: new FormControl<string | null>(null),
    kundengruppe: new FormControl<string | null>(null),
    zahlungskennzeichen: new FormControl<string | null>(null),
    mail: new FormControl<string | null>(null),
    gln: new FormControl<string | null>(null),
    branche: new FormControl<string | null>(null),

    rechnungslieferanten: new FormControl<number>(2),
    lieferadresse: new FormControl<number>(2),
    geloescht: new FormControl<number>(0),
    aktiv: new FormControl<number>(2),
    sperre: new FormControl<number>(2),
  });

  @Input() queryChange: (query: object) => void = () => {};
  @Input() requestSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Input() submit: (query: object) => void = () => {};
  @ViewChildren(FieldValidationDirective, { read: FieldValidationDirective })
  validations!: QueryList<FieldValidationDirective>;

  formRef = viewChild<FormGroupDirective>('formRef');
  query: any;

  async ngOnInit(): Promise<void> {
    this.subscription = this.form.valueChanges.subscribe((o: object) =>
      this.queryChange(o)
    );
    if (this.requestSubmit)
      this.requestSubmitSubscription = this.requestSubmit.subscribe((o) =>
        this.formRef()?.onSubmit(new Event('submit'))
      );
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) this.subscription.unsubscribe();
    this.subscription = undefined;
    if (this.requestSubmitSubscription && !this.requestSubmitSubscription.closed)
      this.requestSubmitSubscription.unsubscribe();
    this.requestSubmitSubscription = undefined;
  }

  async onSubmit(): Promise<void> {
    console.log('onSubmit:', this.form.value);
    console.log(this.ktostammLieferant()?.value);

    this.form.updateValueAndValidity();
    if (this.form.errors) return;

    this.submit(this.form.value);
  }
}
