import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { WithStaticBackNavigation, WithStaticTitleAndSubtitle } from '@softline/application';
import { QueryStore2 } from '@softline/core';
import { TimeReportQuery } from '../../models/time-report.query';
import { Router } from '@angular/router';
import { DynamicModule, WithStaticMetaForm } from '@softline/dynamic';
import { UiCoreModule, Validators } from '@softline/ui-core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  MetaFormCellComponent,
  PageHeaderComponent,
  QueryPageComponent,
  ResponsiveMenuComponent,
  WithQueryPage
} from '@softapps/core';
import { FieldOkObjekt, ObjektFieldOkComponent } from '@softapps/wws/core';
import { timeReportQueryMeta } from '../../models/meta/time-report-query.meta';
import { TimeReportStore2 } from '../../store/time-report.store';

interface Query {
  from: string;
  to: string;
  objectId?: string;
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'soft-time-report-query',
    templateUrl: './query.component.html',
    styleUrls: ['./query.component.scss'],
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule, DynamicModule, QueryPageComponent, PageHeaderComponent, ResponsiveMenuComponent, ObjektFieldOkComponent, MetaFormCellComponent]
})
export class QueryComponent
  extends
    WithQueryPage({
      store: TimeReportStore2,
      queryFeature: (o) => o.query as QueryStore2<TimeReportQuery>,
    },
    WithStaticMetaForm(timeReportQueryMeta,
    WithStaticBackNavigation('/arbeitsbericht',
    WithStaticTitleAndSubtitle('#TIME_REPORT.COMPONENTS.QUERY.TITLE', '#TIME_REPORT.COMPONENTS.QUERY.SUBTITLE'
    ))))
  implements OnInit, OnDestroy {

  override form = new FormGroup({
    from: new FormControl<string | null>(null),
    to: new FormControl<string | null>(null),
    objekt: new FormControl<FieldOkObjekt | null>(null)
  });

  shortcuts = signal([
    {
      title: '#TIME_REPORT.COMPONENTS.QUERY.QUICK_LINKS.CURRENT_WEEK',
      icon: 'fa-regular fa-calendar-day',
      query: {from: 'mo', to: 'so'}
    },
    {
      title: '#TIME_REPORT.COMPONENTS.QUERY.QUICK_LINKS.LAST_WEEK',
      icon: 'fa-regular fa-calendar-week',
      query: {from: 'mo-1', to: 'so-1'}
    },
    {
      title: '#TIME_REPORT.COMPONENTS.QUERY.QUICK_LINKS.CURRENT_MONTH',
      icon: 'fa-regular fa-calendar-lines',
      query: {from: 'ma', to: 'me'}
    }
  ])

  constructor(
    protected router: Router,
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  override async query(query: TimeReportQuery): Promise<void> {
    await super.query(query);
    await this.router.navigate(['/arbeitsbericht', 'result']);
  }
}
