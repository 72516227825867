import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ShopItem} from '../../data/item';
import {Store} from '@softline/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BackNavigable, BackNavigationService, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {SOFTLINE_FEATURE_SHOP_ITEMS} from '../../shop.shared';
import {ItemsStore} from '../../store/items.store';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {ItemListComponent} from '../../components/item-list/item-list.component';
import {
  ItemNavigationContainerComponent
} from '../../components/item-navigation-container/item-navigation-container.component';

@Component({
    selector: 'lib-items',
    imports: [CommonModule, UiCoreModule, ItemNavigationContainerComponent, ItemListComponent],
    templateUrl: './items.component.html',
    styleUrls: ['./items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsComponent implements OnInit, OnDestroy, BackNavigable {

  items$: Observable<ShopItem[]> = this.store.observe(SOFTLINE_FEATURE_SHOP_ITEMS, ItemsStore.getters.all);

  private routerSubscription?: Subscription;

  constructor(private store: Store,
              private activatedRoute: ActivatedRoute,
              protected router: Router,
              private backNavigationService: BackNavigationService) { }

  ngOnInit(): void {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#SHOP.ITEMS.TITLE');
    this.backNavigationService.set(this);

    this.routerSubscription = this.activatedRoute.queryParamMap.subscribe(async params => {
      let queryParams = {};
      if (params.get('attributes')) {
        const itemAttributes: number[] = params.get('attributes')?.split('-')?.map(attributeString => Number(attributeString)) ?? [0];
        const query = { itemAttributes };
        queryParams = (itemAttributes && itemAttributes?.length > 0) ? { query } : {};
      }
      await this.store.dispatch(SOFTLINE_FEATURE_SHOP_ITEMS, ItemsStore.actions.loadMany, { queryParams, clear: true });
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription && !this.routerSubscription.closed)
      this.routerSubscription.unsubscribe();
    this.routerSubscription = undefined;

    this.backNavigationService.set(undefined);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/shop']);
  }
}
