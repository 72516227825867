import { Injectable } from '@angular/core';
import {
  ConnectionHttpService,
  ApiResourceLocation,
} from '@softline/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LabelType } from '@softline/application';
import {Objekt} from '../data/objekt';
import {GET_OBJECT_BY_ID_RESOURCE_PATH, SCAN_OBJECT_RESOURCE_PATH} from '../objektabfrage.api';

@Injectable()
export class ScanOrderService {
  constructor(private service: ConnectionHttpService) {}

  private getObjectId(orderId: string): Observable<number> {
    const params = { type: 'code39', id: orderId };
    const path: ApiResourceLocation = {
      path: SCAN_OBJECT_RESOURCE_PATH,
      pathParams: params,
    };
    return this.service.get<number>(path);
  }

  getObjectForOrder(orderId: string): Observable<Objekt> {
    const path = GET_OBJECT_BY_ID_RESOURCE_PATH;
    const getPath = (id: number): ApiResourceLocation => ({
      path,
      pathParams: { id },
    });
    return this.getObjectId(orderId).pipe(
      switchMap((objectId) => this.service.get<Objekt>(getPath(objectId)))
    );
  }
}
