import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import { combineLatest, combineLatestWith, of, startWith, Subscription } from "rxjs";
import {
  Command,
  CommandStore,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import {LocalStorageService, Store} from '@softline/core';
import {GuardsCheckEnd, GuardsCheckStart, Router, RouterModule} from '@angular/router';
import {SOFTLINE_FEATURE_SHOP_CART, SOFTLINE_FEATURE_SHOP_STAMMDATEN} from '../shop.shared';
import {StammdatenStore} from '../store/stammdaten.store';
import {CartStore} from '../store/cart.store';
import {CartItem} from '../data/cart-item';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {
  PatchContextStore,
  SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
  SOFTLINE_FEATURE_UTILITIES_VKFORGS, VkforgStore
} from '@softapps/allgemein/utils';
import {filter, map} from 'rxjs/operators';

@Component({
    selector: 'lib-shop',
    imports: [CommonModule, UiCoreModule, RouterModule],
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopComponent implements OnInit, OnDestroy {

  private routerSubscription?: Subscription;
  private cartSubscription?: Subscription;
  private locationSubscription?: Subscription;

  loading = false;

  constructor(private store: Store, protected router: Router, private localStorageService: LocalStorageService) { }

  async ngOnInit(): Promise<void> {
    this.cartSubscription = this.store
      .observe(SOFTLINE_FEATURE_SHOP_CART, CartStore.getters.cartItems)
      .subscribe((o) => {
        this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
          name: ShopComponent.name,
          commands: this.createCommands(o)
        });
      });

    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_STAMMDATEN, StammdatenStore.actions.load, { payload: { id: undefined } });

    /*
    this.locationSubscription = combineLatest([
      this.store.observe(SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT, PatchContextStore.getters.data).pipe(map(o => o as any)),
      this.store.observe(SOFTLINE_FEATURE_UTILITIES_VKFORGS, VkforgStore.getters.all)
    ]).pipe(
        filter(([o, vkforgs]) => !!o && vkforgs?.length > 0),
        map(([data, vkforgs]) => {
          if (!data || !data?.vkforginternerauftragid || vkforgs?.length < 1)
            return null;

          return vkforgs.find(o => o.id === data?.vkforginternerauftragid) ?? null
        })
      ).subscribe((vkforg) => {
        if (!vkforg)
          return;

        this.store.commit(SOFTLINE_FEATURE_SHOP_CART, CartStore.mutations.setOrderLocation, { location: vkforg })
      })
     */

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof GuardsCheckStart) {
        this.loading = true;
      }
      if (event instanceof GuardsCheckEnd) {
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription && !this.routerSubscription.closed)
      this.routerSubscription.unsubscribe();
    this.routerSubscription = undefined;

    if (this.cartSubscription && !this.cartSubscription.closed)
      this.cartSubscription.unsubscribe();
    this.cartSubscription = undefined;

    if (this.locationSubscription && !this.locationSubscription.closed)
      this.locationSubscription.unsubscribe();
    this.locationSubscription = undefined;

    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');

    if (this.store.get(SOFTLINE_FEATURE_COMMANDS, CommandStore.getters.commands, ShopComponent.name)) {
      this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, ShopComponent.name);
    }
  }

  protected createCommands(cartItems: readonly CartItem[]): Command[] {
    return [
          {
            icon: 'fa-regular fa-list',
            name: '#SHOP.MENU.ITEM_GROUPS',
            class: 'menu main-menu main-menu-top',
            routerLink: ['shop']
          },
          {
            icon: 'fa-regular fa-bag-shopping',
            name: '#SHOP.MENU.CART',
            class: 'menu main-menu main-menu-top',
            routerLink: ['shop', 'cart']
          },
          {
            icon: 'fa-regular fa-heart',
            name: '#SHOP.MENU.FAVORITES',
            class: 'menu main-menu main-menu-top',
            routerLink: ['shop', 'favorites']
          },
          {
            icon: 'fa-regular fa-magnifying-glass',
            name: '#SHOP.MENU.SEARCH',
            class: 'menu main-menu main-menu-top',
            routerLink: ['shop', 'search']
          },
          {
            icon: 'fa-regular fa-clock-rotate-left',
            name: '#SHOP.MENU.ORDER_HISTORY',
            class: 'menu main-menu main-menu-top',
            routerLink: ['shop', 'orders']
          },
          {
            name: 'Warenkorb',
            icon: 'fa-regular fa-bag-shopping',
            class: 'menu top-menu top-menu-right',
            badge: of(cartItems?.length > 0 ? `${cartItems?.length}` : undefined),
            routerLink: '/shop/cart',
          }
        ];
  }
}
