import {
  Component,
  computed,
  Inject,
  OnDestroy,
  OnInit,
  Signal,
  viewChild,
} from '@angular/core';
import {
  ApplicationModule,
  ArchiveFile,
  ArchiveStore,
  BackNavigable,
  BackNavigationService,
  Command,
  showRequestErrors,
  SOFTLINE_FEATURE_ARCHIVE,
  UploadFileCommand,
  WithActivatedRoute,
  WithDynamicBackNavigation,
  WithStaticTitleAndSubtitle,
} from '@softline/application';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import {
  CollectionStore2,
  ApiResourceLocation,
  DateService,
  RepositoryCollectionStore2,
  Store,
} from '@softline/core';
import {
  ARCHIVE_KEY_RESOURCE_PATH,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST,
} from '../../arbeitsbericht.api';
import { Router } from '@angular/router';
import { TimeReport } from '../../models/time-report.model';
import { SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT } from '../../arbeitsbericht.shared';
import { TimeReportStore, TimeReportStore2 } from '../../store/time-report.store';
import { AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION } from '@softline/auth';
import { DynamicFormComponent, DynamicModule } from '@softline/dynamic';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
} from '@softline/ui-core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SOFTLINE_PERMISSION_TIME_REPORT_PARTY } from '../../arbeitsbericht.permissions';
import {
  ArbeitsberichtConfig,
  SOFTLINE_CONFIG_ARBEITSBERICHT,
} from '../../arbeitsbericht.config';
import { ConfirmDeleteDialogComponent } from '../../components/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
  EditPageComponent,
  PageHeaderComponent,
  WithDynamicEditPage,
} from '@softapps/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  imports: [
    CommonModule,
    UiCoreModule,
    DynamicModule,
    ReactiveFormsModule,
    ApplicationModule,
    EditPageComponent,
    PageHeaderComponent,
  ],
})
export class EditComponent<T>
  extends WithDynamicEditPage(
    {
      store: TimeReportStore2,
      repositoryFeature: (o) => o.repository as RepositoryCollectionStore2<TimeReport>,
      collectionFeature: (o) => o.collection as CollectionStore2<TimeReport>,
      definitionName: SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT,
      headerDefinitionName: SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST,
    },
    WithActivatedRoute(
      WithStaticTitleAndSubtitle(
        '#TIME_REPORT.TITLE',
        '#TIME_REPORT.PAGES.EDIT.SUBTITLE',
        WithDynamicBackNavigation()
      )
    )
  )
  implements OnInit, OnDestroy, BackNavigable
{
  private subscription?: Subscription;
  private commandSubscription?: Subscription;

  private GOOGLE_LINK = 'https://www.google.com/maps/search/?api=1&query=';

  saving$ = new BehaviorSubject<boolean>(false);

  form = viewChild<DynamicFormComponent<TimeReport>>('form');
  override id: Signal<string | number | null> = computed(() => {
    return +this.routeParams()['id'];
  });

  isAuthorized$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.authorized,
    SOFTLINE_PERMISSION_TIME_REPORT_PARTY
  );

  constructor(
    private store: Store,
    protected router: Router,
    @Inject(SOFTLINE_CONFIG_ARBEITSBERICHT)
    readonly timeReportConfig: ArbeitsberichtConfig,
    private dateService: DateService,
    private backNavigationService: BackNavigationService
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    this.canDelete.set(this.timeReportConfig?.allowDelete ?? false);
    await super.ngOnInit();
  }

  override ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) this.subscription.unsubscribe();
    this.subscription = undefined;
    if (this.commandSubscription && !this.commandSubscription.closed)
      this.commandSubscription.unsubscribe();
    this.commandSubscription = undefined;
    super.ngOnDestroy();
  }

  override getBackNavigationRoute(): any[] {
    const timeReport = this.value();
    return ['/arbeitsbericht', { date: timeReport?.date ?? this.dateService.today() }];
  }

  override async delete(): Promise<void> {
    await super.delete();
    await this.router.navigate(this.getBackNavigationRoute());
  }

  protected createCommands(selected: TimeReport): Command[] {
    const imageUpload = new UploadFileCommand(this.store, {
      sources: 'all',
      canExecute: of(true),
      archiveKey: selected?.archiveKey ?? this.getConnectionResourceLocation(selected),
      onSuccess: async (files) => {
        await this.updateArchiveKey(
          selected,
          (files[0] as unknown as { result: ArchiveFile })?.result
        );
      },
    });

    if (this.timeReportConfig && this.timeReportConfig?.allowDelete) {
      return [
        imageUpload,
        {
          name: 'Eintrag löschen',
          class: 'menu action-menu action-menu-top',
          icon: 'fa-regular fa-trash',
          execute: async () => {
            const result = await this.store.dispatch(
              SOFTLINE_FEATURE_MODAL,
              ModalStore.actions.open(),
              {
                component: ConfirmDeleteDialogComponent,
                dismiss: { backdrop: true, button: true },
                id: 'TIME_REPORT_CONFIRM_DELETE',
              }
            );

            if (result && result !== 'DISMISSED') {
              try {
                const timeReport = this.value();

                await this.store.dispatch(
                  SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
                  TimeReportStore.actions.delete,
                  { entity: selected, pathParams: { id: selected.id } }
                );

                await this.store.dispatch(
                  SOFTLINE_FEATURE_MESSAGE_BAR,
                  MessageBarStore.actions.success,
                  { title: 'Eintrag wurde gelöscht!' }
                );

                await this.router.navigate([
                  '/arbeitsbericht',
                  { date: timeReport?.date ?? this.dateService.today() },
                ]);
              } catch (e) {
                showRequestErrors(this.store, e);
              }
            }
          },
        },
      ];
    }

    return [imageUpload];
  }

  private async showUploadSuccess() {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title: 'Upload erfolgreich',
        message: 'Das Bild wurde erfolgreich hochgeladen',
      }
    );
  }

  private async showUploadFailure() {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        title: 'Upload fehlgeschlagen',
        message: 'Das Bild konnte nicht hochgeladen werden',
      }
    );
  }

  private async updateArchiveKey(
    entity: TimeReport,
    { archiveKey }: ArchiveFile
  ): Promise<void> {
    if (!archiveKey) {
      return;
    }
    await this.store.dispatch(SOFTLINE_FEATURE_ARCHIVE, ArchiveStore.actions.read, {
      key: archiveKey,
    });
    this.store.commit(
      SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
      TimeReportStore.mutations.updateArchiveKey,
      { entity, archiveKey }
    );
  }

  private getConnectionResourceLocation(selected: any): ApiResourceLocation {
    return {
      path: ARCHIVE_KEY_RESOURCE_PATH,
      pathParams: { id: selected?.id },
    };
  }

  getGoogleLink(objekt: any): string | undefined {
    let link = this.GOOGLE_LINK;
    if (objekt.geodaten !== null) {
      link += objekt?.geodaten?.gpsbreite + ',' + objekt?.geodaten?.gpslaenge;
      return encodeURI(link);
    }
    return undefined;
  }
}
