<div class="px-3 sm:px-0">
  <soft-artstamm-field-ok-data-view
    #view
    [submit]="submitFn"
    [queryChange]="queryChange"
    [requestSubmit]="requestSubmit"
  >
  </soft-artstamm-field-ok-data-view>
</div>

<button
  class="soft-button primary w-full mt-3 h-14"
  (click)="submitFn(query() ?? {}, view.fullFormValue())"
>
  Abfragen
</button>
