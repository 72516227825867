import {Component, OnDestroy, OnInit} from '@angular/core';
import {Objekt} from '../../data/objekt';
import {Router} from '@angular/router';
import { DynamicModule, EntityView, FieldOkResultConverter } from "@softline/dynamic";
import {
  BackNavigable,
  BackNavigationService,
  LabelType,
  ScannerStore,
  SOFTLINE_FEATURE_SCANNER,
} from '@softline/application';
import {CommonModule} from '@angular/common';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule
} from '@softline/ui-core';
import {ReactiveFormsModule} from '@angular/forms';
import {RecentObjectsDialogComponent} from '../../dialogs/recent-objects-dialog/recent-objects-dialog.component';
import {HttpErrorResponse} from '@angular/common/http';
import {Store} from '@softline/core';
import {
  ObjectQueryRecentObjectsStore,
  SOFTLINE_FEATURE_OBJECT_QUERY,
  SOFTLINE_FEATURE_OBJECT_QUERY_RECENT_OBJECTS
} from '../../objektabfrage.shared';
import {ObjectNotFoundError} from '../../errors/object-not-found.error';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-object-input',
    templateUrl: './object-input.component.html',
    styleUrls: ['./object-input.component.scss'],
    imports: [CommonModule, UiCoreModule, DynamicModule, ReactiveFormsModule]
})
export class ObjectInputComponent implements OnInit, OnDestroy, BackNavigable {

  objectConverter = new FieldOkResultConverter({ objekt: 'number', bezeichnung: 'name', kurzbez: 'description' });

  constructor(
    private router: Router,
    private store: Store,
    private backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.backNavigationService.set(this);
  }

  ngOnDestroy() {
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  async showRecentObjectsDialog(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'RECENT_OBJECTS_DIALOG',
        component: RecentObjectsDialogComponent,
        dismiss: { button: true, escape: true, backdrop: false },
      }
    );

    if (!result || result === 'DISMISSED') {
      return;
    }

    await this.selectObject(result as Objekt);
  }

  async scanOrder(): Promise<void> {
    try {
      const params = { labelType: 'code39' as LabelType };
      const scanResult = await this.store.dispatch(
        SOFTLINE_FEATURE_SCANNER,
        ScannerStore.actions.scan,
        params
      );

      if (!scanResult || !scanResult?.data || !scanResult.labelType) {
        await this.handleInvalidScanResult();
        return;
      }

      const object = await this.store.dispatch(
        SOFTLINE_FEATURE_OBJECT_QUERY_RECENT_OBJECTS,
        ObjectQueryRecentObjectsStore.actions.loadObject,
        { order: scanResult?.data }
      );

      if (!object) {
        await this.handleObjectNotFound();
        return;
      }

      await this.selectObject(object);
    } catch (e) {
      if (e instanceof ObjectNotFoundError) {
        await this.handleObjectNotFound();
      } else if (e instanceof HttpErrorResponse) {
        await this.handleNetworkError();
      } else {
        await this.handleUnknownError();
      }
    }
  }

  async selectObject(objekt?: Objekt): Promise<void> {
    if (!objekt) {
      return;
    }

    await this.router.navigate(['/objektabfrage', objekt.id]);
  }

  private async handleObjectNotFound(): Promise<void> {
    await this.showError();
  }

  private async handleInvalidScanResult(): Promise<void> {
    await this.showError();
  }

  private async handleNetworkError(): Promise<void> {
    await this.showError();
  }

  private async handleUnknownError(): Promise<void> {
    await this.showError();
  }

  private async showError(): Promise<void> {
    const message = {
      title: 'Auftrag nicht gefunden!',
      message: 'Es konnte kein Auftrag mit dem Barcode gefunden werden!',
    };

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      message
    );
  }
}
