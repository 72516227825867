import { Component, input, Signal, Type } from '@angular/core';
import { SearchResultComponent } from './abstraction/search-result.component';
import { Favorite } from '../../user/favorites/favorite';
import { LastUsed } from '../../user/last-used/last-used';

export abstract class SearchStrategy<T, TModalResult = any> {
  abstract get resultViewType(): Type<SearchResultComponent<T>>;
  abstract get queryModalViewType(): Type<any> | undefined;

  abstract results: Signal<T[]>;
  abstract favorites: Signal<Favorite<T>[]>;
  abstract lastUsed: Signal<Favorite<T>[]>;

  abstract activeFilter: Signal<any>;

  abstract loadingState: Signal<'loaded' | 'loading' | 'idle' | 'error'>;

  abstract isManualSearchActive: Signal<boolean>;

  abstract favoriteChange(value: T): void;
  abstract addToLastUsed(value: T): void;
  abstract loadFavorites(): Promise<Favorite<T>[]>;
  abstract loadLastUsed(): Promise<LastUsed<T>[]>;

  abstract search(value: string | null): Promise<T[]>;
  abstract searchInputChange(value: string | null): void;
  abstract setFilter(value: any | null): void;

  abstract modalResolved(result: TModalResult): Promise<void>;
}

@Component({
  selector: 'soft-search-page-default-result-view',
  template: ` <ng-content></ng-content> `,
  standalone: true,
  styles: [],
})
export class DefaultSearchResultViewComponent implements SearchResultComponent<any> {
  public result = input.required<any>();
}
