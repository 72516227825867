import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ShopItem} from '../../data/item';
import {Store} from '@softline/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {map, take} from 'rxjs/operators';
import {Favorite} from '../../data/favorite';
import {CartService} from '../../services/cart.service';
import {CartItem} from '../../data/cart-item';
import {SOFTLINE_FEATURE_SHOP_FAVORITES, SOFTLINE_FEATURE_SHOP_ITEMS} from '../../shop.shared';
import {ItemsStore} from '../../store/items.store';
import {FavoritesStore} from '../../store/favorites.store';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {ListItemComponent} from '../list-item/list-item.component';

export type MetaShopItem = { item: ShopItem; isFavorite?: boolean; isUpdatingFavorite?: boolean; cartAmount?: number | null; };

@Component({
    selector: 'lib-item-list',
    imports: [CommonModule, UiCoreModule, ListItemComponent],
    templateUrl: './item-list.component.html',
    styleUrls: ['./item-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemListComponent {

  @Input() displayType: 'item' |'search' = 'item';
  @Input() isInputEmpty = true;
  @Input() loading?: boolean | undefined = undefined;
  @Input()
  set items(value: ShopItem[]) {
    this.inputItems$.next(value);
  }

  inputItems$ = new BehaviorSubject<ShopItem[]>([]);

  loading$: Observable<boolean> = this.store.observe(SOFTLINE_FEATURE_SHOP_ITEMS, ItemsStore.getters.loading);

  favorites$: Observable<readonly Favorite[]> = this.store.observe(SOFTLINE_FEATURE_SHOP_FAVORITES, FavoritesStore.getters.all);

  updating$: Observable<readonly ShopItem[]> = this.store.observe(
    SOFTLINE_FEATURE_SHOP_FAVORITES, FavoritesStore.getters.selectUpdatingFavorites
  );

  items$: Observable<MetaShopItem[]> = combineLatest([this.favorites$, this.inputItems$, this.updating$, this.cartService.items$]).pipe(
    map(([favorites, items, updatingFavorites, cartItems]) => {
      return this.updateFavoriteInformation(favorites as Favorite[], items, updatingFavorites as ShopItem[], cartItems as CartItem[]);
    })
  );

  private updateFavoriteInformation(
    favorites: Favorite[],
    items: ShopItem[],
    updatingFavorites: ShopItem[],
    cartItems: CartItem[]
  ): MetaShopItem[] {
    return items.map(item => ({
      item,
      isFavorite: !!favorites?.find(fav => fav?.item?.id === item?.id),
      isUpdatingFavorite: !!updatingFavorites?.find(o => o?.id === item?.id),
      cartAmount: cartItems?.find(o => o?.item?.id === item?.id)?.amount ?? null
    }));
  }

  readonly trackByFn = (_: number, item: ShopItem) => item.id;

  constructor(private store: Store,
              private activatedRoute: ActivatedRoute,
              private cartService: CartService) {}

  async onAddToCart(payload: { item: ShopItem, quantity: number | null | undefined }): Promise<void> {
    await this.cartService.showAddToCart(payload.item, payload.quantity);
  }

  async onAddToFavorites(item: ShopItem): Promise<void> {
    const editableItem = this.removeKeys(item, ['tieredPrices', 'linkedItems', 'referencedItems', 'isFavorite', 'isUpdatingFavorite']);
    const entity = { id: editableItem.id, item: editableItem };
    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_FAVORITES, FavoritesStore.actions.add, {entity});
  }

  async onRemoveFromFavorites(item: ShopItem): Promise<void> {
    const entity = await this.getFavoriteForItem(item);
    if (!entity) { return; }
    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_FAVORITES, FavoritesStore.actions.remove, {entity});
  }

  private removeKeys(item: any, keys: (keyof any)[]): ShopItem {
    const itemCopy: any = item;
    keys.forEach(key => {
      if (itemCopy !== null) {
        delete itemCopy[key];
      }
    });
    return itemCopy;
  }

  private async getFavoriteForItem(item: ShopItem): Promise<Favorite | null> {
    return await this.favorites$.pipe(
      map(favorites => favorites.find(fav => fav.item.id === item.id)),
      take(1),
    ).toPromise() ?? null;
  }
}
