import {Inject, Injectable} from '@angular/core';
import {ShopItem} from '../data/item';
import {ConnectionHttpService, ApiResourceLocation, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {ITEM_ENTITIES} from '../shop.api';
import {Observable} from 'rxjs';

@Injectable()
export class ItemResourceService {
  constructor( @Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService ) { }

  search(query: string): Observable<ShopItem[]> {
    const location: ApiResourceLocation = {
      path: ITEM_ENTITIES,
      queryParams: { query }
    };
    return this.service.get<ShopItem[]>(location);
  }
}
