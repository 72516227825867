import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {ShopItem} from '../../data/item';
import {base64Encode, Store} from '@softline/core';
import {BackNavigable, BackNavigationService, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {SOFTLINE_FEATURE_SHOP_ITEMS} from '../../shop.shared';
import {ItemsStore} from '../../store/items.store';
import {Router} from '@angular/router';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {ItemListComponent} from '../../components/item-list/item-list.component';

@Component({
    selector: 'lib-item-search',
    imports: [CommonModule, UiCoreModule, ItemListComponent],
    templateUrl: './item-search.component.html',
    styleUrls: ['./item-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemSearchComponent implements OnInit, OnDestroy, BackNavigable {

  input$ = new BehaviorSubject<string>('');

  debouncedQuery$ = this.input$.pipe(
    debounceTime(250),
    distinctUntilChanged(),
    map(input => ItemSearchComponent.toQueryString(input))
  );

  showMinInputPlaceholder$ = this.input$.pipe(
    map(input => input?.length <= 2 || input === '' || !input)
  );

  items$: Observable<ShopItem[]> = this.store.observe(SOFTLINE_FEATURE_SHOP_ITEMS, ItemsStore.getters.all);
  loading$ = new BehaviorSubject<boolean>(false);

  private subscription?: Subscription;

  private static toQueryString(input: string): string {
    const object = { query: input };
    return base64Encode(JSON.stringify(object));
  }

  constructor(private store: Store,
              protected router: Router,
              private backNavigationService: BackNavigationService) {  }

  async ngOnInit(): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#SHOP.SEARCH.TITLE_BAR_TITLE');
    this.backNavigationService.set(this);

    this.store.commit(SOFTLINE_FEATURE_SHOP_ITEMS, ItemsStore.mutations.clear);
    this.subscription = this.debouncedQuery$.subscribe(async query => {
        if (!this.input$?.value || this.input$.value?.length <= 2) { return; }
        this.loading$.next(true);
        await this.store.dispatch(
          SOFTLINE_FEATURE_SHOP_ITEMS, ItemsStore.actions.search, { query, finished: () => this.loading$.next(false) }
        );
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;

    this.backNavigationService.set(undefined);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/shop']);
  }
}
