import { inject, Provider, provideEnvironmentInitializer, EnvironmentProviders } from '@angular/core';
import { Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import { default as de } from '../i18n/de.json';
import {
  ApplicationStore,
  SOFTLINE_FEATURE_APPLICATIONS,
} from '@softline/application';
import { SOFTLINE_PERMISSION_OBJECT_QUERY } from './objektabfrage.shared';

export const objektabfrageProviders: Array<Provider | EnvironmentProviders> = [
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'objektabfrage',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Objektabfrage',
          icon: 'fa-regular fa-database',
          route: '/objektabfrage',
          permission: SOFTLINE_PERMISSION_OBJECT_QUERY,
        }
      );
    }),
];
