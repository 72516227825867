import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {BackNavigable, BackNavigationService, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {Observable} from 'rxjs';
import {ShopItem} from '../../data/item';
import {Store} from '@softline/core';
import {map} from 'rxjs/operators';
import {SOFTLINE_FEATURE_SHOP_FAVORITES} from '../../shop.shared';
import {FavoritesStore} from '../../store/favorites.store';
import {Router} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ItemListComponent} from '../../components/item-list/item-list.component';
import {UiCoreModule} from '@softline/ui-core';

@Component({
    selector: 'lib-favorites',
    imports: [CommonModule, UiCoreModule, ItemListComponent],
    templateUrl: './favorites.component.html',
    styleUrls: ['./favorites.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoritesComponent implements OnInit, OnDestroy, BackNavigable{

  favorites$: Observable<ShopItem[]> = this.store.observe(SOFTLINE_FEATURE_SHOP_FAVORITES, FavoritesStore.getters.all).pipe(
    map(favorites => favorites.map(favorite => favorite.item))
  );

  constructor(private store: Store,
              protected router: Router,
              private backNavigationService: BackNavigationService) { }

  async ngOnInit(): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#SHOP.FAVORITES.TITLE');
    this.backNavigationService.set(this);
  }

  ngOnDestroy() {
    this.backNavigationService.set(undefined);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/shop']);
  }
}
