import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable} from 'rxjs';
import {CartItem} from '../../data/cart-item';
import {CartService} from '../../services/cart.service';
import {
  BackNavigable,
  BackNavigationService,
  showRequestErrors,
  ScannerStore,
  SOFTLINE_FEATURE_SCANNER,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import {LocalStorageService, Store} from '@softline/core';
import {Objekt} from '../../data/objekt';
import {RecentObjectsDialogComponent} from '../../dialogs/recent-objects-dialog/recent-objects-dialog.component';
import {EditCartItemDialogComponent} from '../../dialogs/edit-cart-item-dialog/edit-cart-item-dialog.component';
import { DynamicModule, EntityView, FieldOkComponent, FieldOkResultConverter } from "@softline/dynamic";
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule
} from '@softline/ui-core';
import {SOFTLINE_FEATURE_SHOP_CART} from '../../shop.shared';
import {CartStore} from '../../store/cart.store';
import {Router} from '@angular/router';
import {CommonModule} from '@angular/common';
import {CartItemComponent} from './cart-item/cart-item.component';
import {PatchContextStore, SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT} from '@softapps/allgemein/utils';

@Component({
    selector: 'lib-cart',
    imports: [CommonModule, UiCoreModule, CartItemComponent, DynamicModule],
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy, BackNavigable {

  objectConverter = new FieldOkResultConverter({ 'objekt': 'number', 'bezeichnung': 'name' });

  @ViewChild('fok') readonly fieldOk: FieldOkComponent<Objekt> | undefined;

  orderLocation$ = this.cartService.getOrderLocation$;

  readonly cartItems$: Observable<readonly CartItem[]> = this.cartService.items$ as Observable<readonly CartItem[]>;
  readonly loading$: Observable<boolean> = this.cartService.loading$;
  readonly isProcessing$ = new BehaviorSubject(false);
  readonly object$: Observable<Objekt | null> = this.cartService.selectedObject$ as Observable<Objekt | null>;

  constructor(private store: Store,
              private cartService: CartService,
              protected router: Router,
              private localStorageService: LocalStorageService,
              private backNavigationService: BackNavigationService) { }

  readonly trackByFn = (_: number, item: CartItem) => item?.id ?? item?.item?.id;

  async ngOnInit(): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#SHOP.CART.TITLE');
    this.backNavigationService.set(this);
    try {
      await this.cartService.load();

      const shopOrderLocation: string = await lastValueFrom(this.localStorageService.get({ key: 'shopOrderLocation' }))
      if (shopOrderLocation) {
        const vkforg = JSON.parse(shopOrderLocation)
        this.store.commit(
          SOFTLINE_FEATURE_SHOP_CART,
          CartStore.mutations.setOrderLocation,
          { location: vkforg }
        );

        this.store.commit(
          SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
          PatchContextStore.mutations.patch, { vkforgidinternerauftrag: vkforg?.id }
        )
      }
    }
    catch (e) {
      showRequestErrors(this.store, e);
    }
  }

  ngOnDestroy() {
    this.backNavigationService.set(undefined);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/shop']);
  }

  async onDelete(item: CartItem): Promise<void> {
    await this.cartService.remove(item);
  }

  async onEdit(item: CartItem): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open(), {
      id: 'SHOP_CART_EDIT',
      component: EditCartItemDialogComponent,
      data: { item, quantity: item.amount },
      dismiss: { button: true, backdrop: false, escape: true }
    });
  }

  async order(value: any): Promise<void> {
    try {
      this.isProcessing$.next(true);
      await this.cartService.order();
    } catch (e) {
      console.log('ERROR: ', e);
      showRequestErrors(this.store, e);
    } finally {
      this.isProcessing$.next(false);
    }
  }

  selectObject(object: Objekt | null | undefined): void {
    if(object)
      this.cartService.selectObject(object);
  }

  async showAddressDialog(): Promise<void> {
    const result: Objekt | null | 'DISMISSED' = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open(), {
      id: 'OBJECT_DIALOG',
      component: RecentObjectsDialogComponent,
      data: { cart: this.cartService.getCart() },
      dismiss: { button: true, escape: true, backdrop: false },
    });
    if (!result || result === 'DISMISSED') { return; }
    this.selectObject(result);
  }

  async scanObject(): Promise<void> {
    try {
      const scan = await this.store.dispatch(SOFTLINE_FEATURE_SCANNER, ScannerStore.actions.scan, {labelType: 'code39'});
      try {
        const object = await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.getObjectForOrder, {
          result: scan.data,
          labelType: typeof scan.labelType === 'string' ? scan.labelType : scan.labelType ? scan.labelType[0] : '',
        });
        if (object !== undefined) { this.selectObject(object); }
      } catch {
        await this.scanGetObjectFailure();
      }
    } catch {
      await this.scanFailure();
    }
  }

  private async scanFailure(): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.error, 'Beim Scannen ist ein Fehler aufgetreten');
  }

  private async  scanGetObjectFailure(): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.error,
      'Beim Abrufen des gescannten Objekts ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung.'
    );
  }
}
