import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal, UiCoreModule } from '@softline/ui-core';

@Component({
    selector: 'lib-confirm-delete-dialog',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './confirm-delete-dialog.component.html',
    styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent implements Modal<boolean> {
  close!: (result: boolean) => void;

  constructor() {}

  registerCloseHandler(handler: (result: boolean) => void) {
    this.close = handler;
  }
}
