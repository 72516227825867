import {
  ConnectionHttpService,
  ApiResourceLocation,
  Dictionary, ResponseResult
} from '@softline/core';
import {
  ARCHIVE_KEY_RESOURCE_PATH,
  TIME_REPORT_DETAIL_RESOURCE_PATH,
  SOFTLINE_API_TIME_REPORT,
  OBJECTS_RESOURCE_PATH,
} from '../arbeitsbericht.api';
import { TimeReport } from '../models/time-report.model';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  ArbeitsberichtConfig,
  SOFTLINE_CONFIG_ARBEITSBERICHT,
} from '../arbeitsbericht.config';
import { Objekt } from '../models/objekt.model';

export class TimeReportResourceService {
  private static asTimeReport(report: any): TimeReport {
    return report as TimeReport;
  }

  constructor(
    private timeReportConfig: ArbeitsberichtConfig,
    private service: ConnectionHttpService
  ) {}

  getMany(queryParams?: Dictionary<unknown>): Observable<{ result: TimeReport[], sum: Partial<TimeReport>[] }> {
    const location = {
      path: SOFTLINE_API_TIME_REPORT,
      queryParams,
    };
    return this.service.get<{ result: TimeReport[], sum: Partial<TimeReport>[] }, unknown>(location);
  }

  create(
    entity: Partial<TimeReport>,
    permission: boolean,
    pathParams?: Dictionary<unknown>
  ): Observable<{ result: TimeReport, sum: Partial<TimeReport>[] }> {
    delete (entity as any)['images'];

    if (!permission && entity?.group) {
      delete entity['group'];
    }

    const location: ApiResourceLocation = {
      path: SOFTLINE_API_TIME_REPORT,
      pathParams,
    };

    return this.service.create<any, { result: TimeReport, sum: Partial<TimeReport>[] }>(location, entity);
  }

  getArchiveKey(id: string): Observable<string> {
    const location: ApiResourceLocation = {
      path: ARCHIVE_KEY_RESOURCE_PATH,
      pathParams: { id },
    };
    return this.service.get<string>(location);
  }

  getReportDetails(id: string, group: boolean): Observable<TimeReport> {
    const location: ApiResourceLocation = {
      path: TIME_REPORT_DETAIL_RESOURCE_PATH,
      pathParams: { id },
      queryParams: { group },
    };

    return this.service.get<ResponseResult<TimeReport>>(location).pipe(
      switchMap(({ result }) => {
        if (!result?.object?.id)
          return of(result);

        const objektLocation: ApiResourceLocation = {
          path: OBJECTS_RESOURCE_PATH + '/' + result.object?.id,
        };

        return this.service
          .get<ResponseResult<Objekt>>(objektLocation)
          .pipe(
            map((o) => ({
              ...result,
              object: {
                ...(o.result ?? result?.object),
                objekt: o?.result?.number,
                bezeichnung: o?.result?.name,
              },
            }))
          );
      })
    );
  }
}
