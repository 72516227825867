import { BehaviorSubject } from 'rxjs';
import { RelatedApp } from '../settings/pwa-install-settings/pwa-install-settings.component';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_PWA_INSTALL } from '../../pwa.shared';
import { PwaInstallStore } from '../store';
import { AppInfoStore, SOFTLINE_FEATURE_APP_INFO } from '@softline/application';
import { MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR } from '@softline/ui-core';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class PwaInstallService {
  deferredPrompt: any;

  constructor(
    private store: Store,
    private swUpdate: SwUpdate
  ) {}

  async init(): Promise<void> {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      // Save the event because you'll need to trigger it later.
      this.deferredPrompt = e;
      this.store.commit(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.mutations.patch, {
        canInstall: !!e,
        installed: !e,
      });
    });

    this.swUpdate.versionUpdates.subscribe(async (evt) => {
      this.store.commit(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.mutations.patch, {
        hasServiceWorker: true,
      });
    });

    const serviceWorkers = await navigator.serviceWorker.getRegistrations();
    const hasServiceWorker = serviceWorkers.length > 0;

    let relatedApps: RelatedApp[] = [];
    if (navigator['getInstalledRelatedApps']) {
      relatedApps = await (navigator as any).getInstalledRelatedApps();
    }
    const isPwa = window.matchMedia('(display-mode: standalone)').matches;
    const hasWebShare = !!navigator.share && !!navigator.canShare;

    this.store.commit(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.mutations.set, {
      hasServiceWorker,
      isPwa,
      hasWebShare,
      relatedApps,
      canInstall: this.deferredPrompt ? !!this.deferredPrompt : false,
      installed: !this.deferredPrompt,
    });
  }

  async installApp(): Promise<boolean> {
    if (!this.deferredPrompt)
      throw new Error('[PwaInstallService] installApp: deferredPrompt is empty');
    this.deferredPrompt.prompt();
    const { outcome } = await this.deferredPrompt.userChoice;
    // The deferredPrompt can only be used once.
    this.deferredPrompt = undefined;

    if (outcome === 'accepted') {
      console.log('[PwaInstallService] User accepted the install prompt.');
      this.store.commit(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.mutations.patch, {
        isPwa: true,
      });
    } else if (outcome === 'dismissed') {
      console.log('[PwaInstallService] User dismissed the install prompt');
    }
    this.store.commit(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.mutations.patch, {
      canInstall: false,
    });
    return outcome === 'accepted';
  }

  async share(): Promise<void> {
    if (!navigator.canShare || !navigator.share)
      throw new Error('[PwaInstallService] share: no ShareAPI available in browser');
    const title = this.store.get(SOFTLINE_FEATURE_APP_INFO, AppInfoStore.getters.name);
    const url = document.location.href;
    if (navigator.canShare({ title, url })) await navigator.share({ title, url });
  }

  async registerNgServiceWorker(): Promise<void> {
    try {
      await navigator.serviceWorker.register('ngsw-worker.js');
      this.store.commit(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.mutations.patch, {
        hasServiceWorker: true,
      });
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#PWA.INSTALL.MESSAGES.SUCCESS.REGISTER_SERVICEWORKER'
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#PWA.INSTALL.MESSAGES.ERROR.REGISTER_SERVICEWORKER'
      );
    }
  }

  async unregisterNgServiceWorker(): Promise<void> {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (let registration of registrations) await registration.unregister();
      this.store.commit(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.mutations.patch, {
        hasServiceWorker: false,
      });

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#PWA.INSTALL.MESSAGES.SUCCESS.UNREGISTER_SERVICEWORKER'
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#PWA.INSTALL.MESSAGES.ERROR.UNREGISTER_SERVICEWORKER'
      );
    }
  }
}
