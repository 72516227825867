import {
  Component,
  computed,
  inject,
  input,
  OnInit,
  output,
  Signal,
} from '@angular/core';
import {
  CollectionStore2,
  DateService,
  RepositoryCollectionStore2,
  Store,
} from '@softline/core';
import { SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST } from '../../arbeitsbericht.api';
import { TimeReport } from '../../models/time-report.model';
import { TimeReportStore2 } from '../../store/time-report.store';
import { DynamicModule, WithDefinition } from '@softline/dynamic';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';
import {
  AuthenticationContextStore,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
} from '@softline/auth';
import { RemoteDataContainerComponent } from '@softapps/core';
import { WithLoadRepositoryCollection, WithRefresh, WithRepositoryCollection } from '@softline/application';

@Component({
    //tslint:disable-next-line:component-selector
    selector: 'soft-time-report-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    imports: [
        CommonModule,
        UiCoreModule,
        DynamicModule,
        RemoteDataContainerComponent,
    ]
})
export class ListComponent
  extends WithRefresh(
    WithDefinition(
      SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST,
      WithLoadRepositoryCollection({
        store: TimeReportStore2,
        collectionFeature: (o) => o.collection,
        repositoryFeature: (o) => o.repository,
      })
    )
  )
  implements OnInit
{
  private dateService = inject(DateService);
  private timeReportStore = inject(TimeReportStore2);

  date = input(this.dateService.today());

  selectedChange = output<TimeReport>();

  user = this.store.signal(
    SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
    AuthenticationContextStore.getters.data
  ) as Signal<{ personalid: number }>;

  readonly totalTime = this.timeReportStore.sum.values as Signal<(TimeReport & { bewegungsart: { bezeichnung?: string }})[]>;

  override loadParams = computed(() => {
    const date = this.date().substring(0, 10);
    const lstfixId = this.user()?.personalid;
    return { query: { from: date, to: date, lstfixId} };
  });

  constructor(private store: Store) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  override async refresh(): Promise<void> {
    await this.load();
  }
}
