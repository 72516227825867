import {
  ConnectionHttpService,
  ApiResourceLocation,
} from '@softline/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SOFTLINE_API_OBJEKT_ARCHIVE_KEY } from '../objektabfrage.api';

@Injectable()
export class ArchiveKeyService {
  constructor(private service: ConnectionHttpService) {}

  loadArchiveKey(id: number | string): Observable<string> {
    const location: ApiResourceLocation = {
      path: SOFTLINE_API_OBJEKT_ARCHIVE_KEY,
      pathParams: { id },
    };
    return this.service.get(location);
  }
}
